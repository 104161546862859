@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,200italic);

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.additional-margin-top {
    padding-top: 40px;
}

.text-center {
    text-align: center;
}

.quote-card {
    background: #fff;
    color: #222222;
    padding: 20px;
    padding-left: 50px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(34, 34, 34, 0.12);
    position: relative;
    overflow: hidden;
    min-height: 120px;
}
.quote-card p {
    font-size: 19px;
    line-height: 1.5;
    margin: 0;
    max-width: 80%;
}
.quote-card cite {
    font-size: 16px;
    margin-top: 10px;
    display: block;
    font-weight: 200;
    opacity: 0.8;
}
.quote-card:before {
    font-family: Georgia, serif;
    content: "“";
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 5em;
    color: rgba(238, 238, 238, 0.8);
    font-weight: normal;
}
.quote-card:after {
    font-family: Georgia, serif;
    content: "”";
    position: absolute;
    bottom: -110px;
    line-height: 100px;
    right: -32px;
    font-size: 25em;
    color: rgba(238, 238, 238, 0.8);
    font-weight: normal;
}
@media (max-width: 640px) {
    .quote-card:after {
        font-size: 22em;
        right: -25px;
    }
}